import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, OutlinedInput, Typography } from '@mui/material'
import { ErrorText, MButton, ProgressLoaders } from 'components'
import { MESSAGES, ROUTES_PATH, ServiceFormRequestType, USER_TOKEN } from 'config'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Controller, useForm, type Control, type FieldErrors } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import * as yup from 'yup'

interface ServiceFormType {
    zipCode: string
    address1: string
    address2: string
    requestDate: string
    homeTelephone: string
    city: string
    state: string
    mobile: string
    email: string
    comments: string
    attachmentURL: string
}

interface ServiceFormProps {
    formType: string
    title: string
    desc: string
    icon: any
    handleClick: () => void
}


const ServiceForm: React.FC<ServiceFormProps> = (props: ServiceFormProps) => {
    const isMobile = useMobileBreakpoints()
    const { account, currentAccount, getAccount, isAdmin, userEmail } = useAuth()
    const { enqueueSnackbar } = useSnackbar()
    const [authToken] = useLocalStorage(USER_TOKEN, '')
    const navigate = useNavigate()
    const schema = yup
        .object({
            city: yup.string().required('This field is required'),
            state: yup.string().required('This field is required'),
            zipCode: yup.string().required('This field is required'),
            address2: yup.string().required('This field is required'),
            requestDate: yup.string().required('This field is required'),
            homeTelephone: yup.string().required('This field is required'),
            email: yup.string().required('This field is required'),
            mobile: yup.string().required('This field is required')
        })
        .required()
    const {
        handleSubmit,
        control,
        formState: { errors, isValid }
    } = useForm({
        mode: 'all',
        defaultValues: {
            city: account?.myAccount?.serviceAddresses?.[0]?.serviceAddressDetails.city ?? '',
            state: account?.myAccount?.serviceAddresses?.[0]?.serviceAddressDetails.state ?? '',
            zipCode: account?.myAccount?.serviceAddresses?.[0]?.serviceAddressDetails.postal ?? '',
            address1: '',
            address2: account?.myAccount?.serviceAddresses?.[0]?.serviceAddressDetails.addressLine1 ?? '',
            requestDate: '',
            homeTelephone: account?.myAccount?.alternateNumber ?? '',
            email: account?.myAccount?.email ?? '',
            mobile: account?.myAccount?.mobileNumber ?? '',
            attachmentURL: '',
            comments: ''
        },
        resolver: yupResolver(schema)
    })
    const [prevAccountId, setPrevAccountId] = useState<string>('')

    useEffect(() => {
        if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
            return
        }
        if (currentAccount?.accountId !== null) {
            void getAccount({
                AccessToken: authToken,
                accountId: currentAccount?.accountId,
                admin: isAdmin,
                email: userEmail
            })
        }
        setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, currentAccount])


    const onSubmit = async (data: ServiceFormType): Promise<void> => {
        try {
            const formType: string = props.formType
            const body = {
                ...data,
                AccessToken: authToken,
                accountId: account?.myAccount?.accountId ?? '',
                requestType: ServiceFormRequestType[formType as keyof typeof ServiceFormRequestType]
            }
            await APIs.sendServiceRequest(body)
            enqueueSnackbar(MESSAGES.FORM_SUBMIT_SUCCESS, {
                variant: 'success'
            })
            setTimeout(() => { navigate(ROUTES_PATH.DASHBOARD) }, 5000)
        } catch (error) {
            enqueueSnackbar(MESSAGES.FORM_SUBMIT_ERROR, {
                variant: 'error'
            })
        }
    }

    return (
        <>
            {account !== null
                ? <form method="post" onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" justifyContent="space-between" mb={2} gap={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <props.icon sx={{ color: '#84B0DA', fontSize: 36 }} />
                            <Typography fontSize={isMobile ? 18 : 20} fontWeight={600} color="#010B0E">
                                {props.title}
                                <Typography fontSize={14} color="#5D6365">
                                    {props.desc}
                                </Typography>
                            </Typography>
                        </Box>
                        <Button sx={{ color: '#4D515F' }} startIcon={<ArrowBackIcon />} onClick={() => { props.handleClick() }}>Back to Menu</Button>
                    </Box>
                    <Box bgcolor='#F9F9F9' p={2} my={2}>
                        <Typography fontSize={16} mb={1} fontWeight={600} color="#010B0E">
                            Account Information
                        </Typography>
                        <Box display="flex" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
                            <Box>
                                <FormLabel>ACCOUNT NUMBER</FormLabel>
                                <Typography fontSize={isMobile ? 14 : 18} color="#010B0E">{account?.myAccount?.accountId}</Typography>
                            </Box>
                            <Box>
                                <FormLabel>PREMISE ID</FormLabel>
                                <Typography fontSize={isMobile ? 14 : 18} color="#010B0E">{account?.myAccount?.serviceAddresses?.[0].premiseId ?? ''}</Typography>
                            </Box>
                            <Box>
                                <FormLabel>PREMISE ID</FormLabel>
                                <Typography fontSize={isMobile ? 14 : 18} color="#010B0E">{account?.myAccount?.serviceAddresses?.[0].serviceAddress ?? ''}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <Typography fontSize={16} mb={1} fontWeight={600} color="#010B0E">
                            Service Address
                        </Typography>
                        <InputWithLabel fieldLabel="City" fieldName='city' fieldType="text" control={control} errors={errors} />
                        <InputWithLabel fieldLabel="State" fieldName='state' fieldType="text" control={control} errors={errors} />
                        <InputWithLabel fieldLabel="Zip code" fieldName='zipCode' fieldType="text" control={control} errors={errors} />
                        <InputWithLabel fieldLabel="Apt/Unit Number (Optional)" fieldName='address1' fieldType="text" control={control} errors={errors} />
                        <InputWithLabel fieldLabel="Address (e.g. 1234 Main St.)" fieldName='address2' fieldType="text" control={control} errors={errors} />
                        <InputWithLabel fieldLabel="Request Date" fieldName='requestDate' fieldType="date" control={control} errors={errors} />
                    </Box>
                    <Box mb={2}>
                        <Typography fontSize={16} mb={1} fontWeight={600} color="#010B0E">
                            Contact Information
                        </Typography>
                        <InputWithLabel fieldLabel="Home/Office Number" fieldName='homeTelephone' fieldType="text" control={control} errors={errors} />
                        <InputWithLabel fieldLabel="Email" fieldName='email' fieldType="text" control={control} errors={errors} />
                        <InputWithLabel fieldLabel="Mobile Number" fieldName='mobile' fieldType="text" control={control} errors={errors} />
                    </Box>
                    <Box mb={2}>
                        <Typography fontSize={16} mb={1} fontWeight={600} color="#010B0E">
                            Additional Information
                        </Typography>
                        <InputWithLabel fieldLabel="Additional Comments (Optional)" fieldName='comments' fieldType="text" control={control} errors={errors} />
                    </Box>
                    <Box my={3} textAlign="center">
                        <MButton variant="contained" type="submit" size="large" rounded="true" disabled={!isValid} sx={{ width: isMobile ? '100%' : 250 }}>
                            SUBMIT
                        </MButton>
                    </Box>
                </form>
                : <Box mb={5}><ProgressLoaders height="100%" /></Box>}
        </>
    )
}

export default ServiceForm


interface InputWithLabelProps {
    fieldName: string
    fieldLabel: string
    fieldType: string
    control: Control<any>
    errors: FieldErrors<any>
}
export const InputWithLabel: React.FC<InputWithLabelProps> = (props: InputWithLabelProps) => {
    return <Controller
        name={props.fieldName}
        control={props.control}
        render={({ field: { onChange, value, name } }): JSX.Element => (
            <Box mb={2}>
                <FormLabel>{props.fieldLabel.toUpperCase()}</FormLabel>
                <OutlinedInput
                    name={name}
                    placeholder={`Enter your ${props.fieldLabel.toLowerCase()}`}
                    onChange={onChange}
                    value={value}
                    type={props.fieldType}
                    fullWidth
                />
                {(props.errors[props.fieldName] != null) && (
                    <ErrorText>{String(props.errors[props.fieldName]?.message)}</ErrorText>
                )}
            </Box>
        )}
    />
}
